@charset "utf-8";
/* ============================================================ */
/* about.html */
/* ============================================================ */
.about {
	.about-commitment {
		background: url(/common/img/about/bg_about_01.png) bottom right 10% no-repeat;
		background-size: contain;
		.ttl03 {
			text-align: center;
			width: 20%;
			margin: 0 auto 30px;
			&::after {
				@include centering-elements(true, false);
				width: 30%;
			}
		}
		.about-copy {
			display: flex;
			justify-content: center;
			&__item {
				writing-mode: vertical-rl;
				font-size: 2.6rem;
				margin: 20px auto 50px;
				display: table-cell;
			}
			_:lang(x)+_:-webkit-full-screen-document, .about-copy__item {
				transform: rotate(90deg);
				margin: 40px 0 50px 0;
			}
			_:lang(x)+_:-webkit-full-screen-document, .about-copy__item span.u-pt15 {
				padding: 0px 0 0 15px;
			}
			_:lang(x)+_:-webkit-full-screen-document, .about-copy__item span.u-pt45 {
				padding: 0px 0 0 45px;
			}
		}
	}
	
	.about-img {
		display: flex;
		&__item {
			width: calc( 100% / 3 );
		}
	}
	
	.about-cont {
		background: url(/common/img/common/bg_pattern_01.png) center center repeat;
		background-size: 1600px auto;
		&__item {
			position: relative;
			padding: 100px 0 0;
			&__img {
				position: absolute;
				top: 100px;
				max-width: 980px;
				@media screen and (min-width:  767.5px) and (max-width:1300px) { width: 80%; }
			}
			.row__inner {
				padding: 30px 20px 0;
			}
			&__body {
				background: rgba(255,255,255,0.95);
				margin-top: 20px;
				width: 100%;
				z-index: 2;
				position: relative;
				padding: 50px 0 50px 50px;
				min-height: 540px;
				@media screen and (min-width:  767.5px) and (max-width:1024px) { width: 50%;padding: 30px 0 30px 30px;min-height:inherit; }
				@media screen and (min-width:  1024px) and (max-width:2100px) { width: 50%;}
				&__text {
					padding-right: 50px;
					@media screen and (min-width:  767.5px) and (max-width:1024px) { padding-right: 30px; }
				}
			}
			&--left {
				.about-cont__item__img {
					left: 0;
				}
				.about-cont__item__body {
					margin-left: auto;
				}
			}
			&--right {
				.about-cont__item__img {
					right: 0;
				}
				.about-cont__item__body {
					margin-right: auto;
				}
			}
			&:last-child {
				.row__inner {
					padding: 30px 20px 100px;
				}
			}
		}
	}
}