@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
header {
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	
	.inner {
		max-width: 100%;
		margin: 0 auto;
		padding: 10px 10px 0 15px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.header-logo {
			flex: 1 0 auto;
			.clone-logo {
				display: none;
			}
		}
		.header-contact {
			margin: 8px 5px 0 0;
			a {
				font-size: 2.8rem;
				color: $white;
				line-height: 1.0;
				text-decoration: none;
			}
		}
	}

	/* .header-menu
	----------------------------------------------------------- */
	.header-menu,
	.header-menu span {
		@include transition;
	}
	.header-menu {
		position: relative;
		z-index: 150;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50px;
		height: 26px;
		cursor: pointer;

		&__item {
			display: inline-block;
			position: absolute;
			left: 10px;
			width: 30px;
			height: 2px;
			background: $white;
			@include radius(2);

			&:nth-of-type(1) { top: 0px; }
			&:nth-of-type(2) { top: 12px; }
			&:nth-of-type(3) { bottom: 0px; }
		}
		&.active {
			.header-menu__item {
				background: $white;
				&:nth-of-type(1) { transform: translateY(10px) rotate(-315deg); }
				&:nth-of-type(2) { opacity: 0; }
				&:nth-of-type(3) { transform: translateY(-14px) rotate(315deg); } 
			}
		}
		.fixed & {
			position: fixed;
			top: 20px;
			right: 10px;
			background: transparent;
		}
	}


	/* .gnav
	----------------------------------------------------------- */
	.gnav {
		position: fixed;
		left: 0;
		top: 0;
		z-index: -1;
		width: 100vw;
		height: 100vh;
		padding: 80px 15px 15px;
		background: $baseColor;
		opacity: 0;
		visibility: hidden;
		@include transition;

		&__item {
			display: flex;
			align-items: center;
			padding: 15px 10px;
			border-bottom: 1px solid rgba($white, 0.2);
			color: $white;
			font-size: $m;
			text-decoration: none;
			
			&:first-child {
				border-top: 1px solid rgba($white, 0.2);
			}
			.icon-angle-right {
				margin: 0 10px 0 0;
				color: $white;
			}
		}
		
		.fixed & {
			z-index: 6;
			opacity: 1;
			visibility: visible;
		}
	}

	/* .header-icon
	----------------------------------------------------------- */
	.header-icon {
		order: 2;
		width: 50px;
		height: 50px;
		background: $baseColor;

		&__link {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
			font-size: $xxl;
			text-decoration: none;
			color: $white;
		}
	}
	
}

/* ============================================================ */
/* clone-header */
/* ============================================================ */
.clone-header {
	position: fixed;
	top: 0;
	left: 0;
	height: 55px;
	z-index: 10;
	background: rgba(255,255,255,.9);
	font-size: $s;
	transition: .4s;
	transform: translateY(-100%);
	visibility: hidden;
	font-family: 'Noto Serif JP', serif;
	.inner {
		padding: 5px 10px 0 15px;
		.header-logo {
			.index-logo {
				display: none;
			}
			.clone-logo {
				display: block;
			}
		}
		.header-contact {
			a {
				color: $baseColor;
			}
		}
	}

	.header-menu {
		&__item {
			background: $baseColor;
		}
	}

	.header-fixed & {
		transform: translateY(0);
		visibility: visible;
	}
}

.is-show {
	box-shadow: 0 3px 3px rgba(#000, 0.2);
	transform: translateY(0);
	visibility: visible;
}

.fixed header .gnav,
.fixed.header-fixed header.clone-header .gnav {
	display: block;
	visibility: visible;
	z-index: 100;
}

.fixed .clone-header .gnav,
.fixed.header-fixed header .gnav {
	display: none;
	visibility: hidden;
	z-index: -1;
}

.fixed.header-fixed header:not(.clone-header) .header-menu {
	display: none;
}