@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
.footer-contact {
	border-top: 1px solid #e6e6e6;
	.inner {
		padding: 30px 10px 40px;
		text-align: center;
		.time-info {
			margin-left: 30px;
		}
	}
	.font-tel {
		justify-content: center;
		margin: 0 auto;
		width: 80%;
		height: 50px;
		color: $white;
		line-height: 1.0;
		font-size: $xxxl;
		background: #ba0725;
		position: relative;
		.icon-angle-right {
			@include centering-elements(false, true);
			right: 10px;
			font-size: $s;
		}
	}
	&__bnr {
		padding: 0 30px;
		&__item {
			display: block;
			position: relative;
			&::after {
				content: "";
				width: 95%;
				height: 85%;
				@include centering-elements(true, true);
				border: 1px solid rgba(255,255,255,0.4);
			}
			&__text {
				width: 95%;
				height: 95%;
				@include centering-elements(true, true);
				color: $white;
				font-size: 2.2rem;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.icon-angle-right {
				@include centering-elements(false, true);
				right: 15px;
				color: $white;
			}
		}
	}
}
footer {
	background: $baseColor;
	padding: 30px 0 0;
	text-align: center;
	
	.footer-logo {
		display: block;
		width: 120px;
		margin: 0 auto 20px;
	}
	.footer-address {
		color: #cccccc;
		font-size: 1.5rem;
		text-align: center;
		margin-bottom: 40px;
		.time-info {
			margin-left: 30px;
		}
		a[href^="tel:"] {
			color: #cccccc;
		}
		.footer-inst {
			margin: 0 auto;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			width: 40px;
			height: 40px;
			border: 1px solid #cccccc;
			.icon-instagram,
			.icon-facebook {
				color: #cccccc;
				font-size: $xxl;
				text-decoration: none;
			}
		}
	}
	
	.fnav {
		border-top: 1px solid #4d4d4d;
		&__body {
			&__item {
				border-bottom: 1px solid #4d4d4d;
				width: 100%;
				text-align: left;
				position: relative;
				a {
					width: 100%;
					height: 100%;
					display: block;
					padding: 10px 15px;
					color: #cccccc;
					text-decoration: none;
				}
				.icon-angle-right {
					color: #cccccc;
					@include centering-elements(false, true);
					right: 10px;
				}
			}
		}
	}


	.copyright {
		padding: 20px 0;
		background: #000;
		color: $white;
		font-size: $xxs;
	}
}