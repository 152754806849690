@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
header {
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	
	.inner {
		max-width: 1040px;
		margin: 0 auto;
		padding: 20px;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		.header-logo {
			width: 148px;
			&:hover img {
				opacity: 0.7;
				transition: 0.3s all;
			}
			.clone-logo {
				display: none;
			}
		}
		.gnav {
			display: flex;
			justify-content: space-between;
			margin: 10px 0 0;

			&__item {
				text-decoration: none;
				border-right: 1px solid rgba(255,255,255,0.4);
				color: $white;
				width: 48px;
				padding: 0 0 45px;
				text-align: center;
				font-size: 1.6rem;
				letter-spacing: 0.02em;
				line-height: 1.0;
				position: relative;
				font-weight: $font-medium;
				

				&:first-child {
					border-left: 1px solid rgba(255,255,255,0.4);
				}

				&__text {
					writing-mode: vertical-rl;
					
				}

				.icon-angle-down {
					@include centering-elements(true, false);
					bottom: 0;
				}
				&:hover {
					opacity: 0.7;
					transition: 0.3s all;
				}
			}
		}
	}
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  .gnav__item__text {
	  height: 145px;
	  text-align: initial;
  }
}
/* .clone-header
----------------------------------------------------------- */
.clone-header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 20;
	background: rgba(255,255,255,0.9);
	transform: translateY(-100%);
	box-shadow: 0 3px 3px rgba(0,0,0,.2);
	@include transition;
	font-family: 'Noto Serif JP', serif;
	.inner {
		max-width: 100%;
		padding: 10px 15px;
		align-items: center;
		justify-content: flex-start;
		.header-logo {
			width: 150px;
			margin-right: 15px;
			@media screen and (min-width:  767.5px) and (max-width:1024px) { width: 120px;margin-right: 10px; }
			.index-logo {
				display: none;
			}
			.clone-logo {
				display: block;
			}
		}
		.gnav {
			margin: 0;
			&__item {
				border-right: none;
				color: $baseColor;
				width: auto;
				padding: 20px 12px;
				text-align: center;
				font-size: 1.5rem;
				letter-spacing: normal;
				line-height: 1.0;
				@media screen and (min-width:  767.5px) and (max-width:1024px) { padding: 20px 10px;font-size: 1.4rem; }
				&--top {
					display: none;
				}
				.icon-angle-down {
					display: none;
				}
				&:first-child {
					border-left: none;
				}
				&__text {
					writing-mode: horizontal-tb;
					height: auto;
				}
				&::before {
					position: absolute;
					left: 50%;
					bottom: 0;
					z-index: 5;
					content: "";
					width: 0;
					height: 1px;
					background: $baseColor;
					backface-visibility: hidden;
					transform: translateX(-50%);
					@include transition;
				}
				&:hover {
					opacity: 1.0;
					&::before {
						width: 100%;
					}
				}
			}
		}
	}
}
.is-show {
  transform: translateY(0);
}
