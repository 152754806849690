@charset "utf-8";
/* ============================================================ */
/* .contents */
/* ============================================================ */
.contents {
	.row {
		&__inner {
			width: 100%;
			padding: 30px 10px 40px;
			margin: 0 auto;
		}
	}
}





/* ============================================================ */
/* 汎用スタイル */
/* ============================================================ */
/* ============================================================ */
/* 汎用スタイル */
/* ============================================================ */
.bg-pattern {
	background: url(/common/img/common/bg_pattern_01.png) center center repeat;
	background-size: 1600px auto;
}

.link_scroll {
	margin-top: -55px!important;
	padding-top: 55px!important;
}
/* --------------------------------------------------- */
/* テキスト */
/* --------------------------------------------------- */
p {
	margin-bottom: 20px;
	
	&:last-child {
		margin-bottom: 0;
	}
}

.indent {
	display: table;
	
	& > *:first-child {
		display: table-cell;
		white-space: nowrap;
	}
}

.font-tel {
	font-size: 3.4rem;
	display: flex;
	align-items: center;
	text-decoration: none;
}

.time-info {
	line-height: 1.0;
	margin-bottom: 10px;
	&__label {
		border-right: 1px solid #cccccc;
		padding-right: 10px;
	}
}

/* --------------------------------------------------- */
/* 下層ヘッダー */
/* --------------------------------------------------- */
.under-header {
	height: 150px;
	background-image: url(/common/img/common/bg_header.png);
	background-position: center center;
	background-size: cover;
	position: relative;
	&::after {
		content: "";
		border-right: 1px solid $white;
		@include centering-elements(true, false);
		bottom: 0;
		height: 30px;
	}
	
	.about & { background-image: url(/common/img/about/bg_header.png);}
	.menu & { background-image: url(/common/img/menu/bg_header.png);}
	.room & { background-image: url(/common/img/room/bg_header.png);}
	.scene & { background-image: url(/common/img/scene/bg_header.png);}
	.gallery & { background-image: url(/common/img/gallery/bg_header.png);}
	.access & { background-image: url(/common/img/access/bg_header.png);}
	.news & { background-image: url(/common/img/news/bg_header.png);}
	.contact & { background-image: url(/common/img/contact/bg_header.png);}
	
}

/* --------------------------------------------------- */
/* 見出し */
/* --------------------------------------------------- */
.ttl01 {
	font-size: 2.8rem;
	text-align: center;
	font-weight: $font-medium;
	padding: 40px 0 0;
	position: relative;
	&::before {
		content: "";
		border-right: 1px solid $baseColor;
		@include centering-elements(true, false);
		top: 0;
		height: 33px;
	}
}

.ttl02 {
	text-align: center;
	margin-bottom: 15px;
	&--ja {
		font-size: 2.2rem;
		line-height: 1.2;
		font-weight: $font-medium;
	}
	&--en {
		line-height: 1.0;
		color: #b3b3b3;
		font-size: 1.5rem;
		font-weight: $font-regular;
	}
}

.ttl03 {
	font-size: 2.4rem;
	font-weight: $font-medium;
	position: relative;
	border-bottom: 1px solid #1a1a1a;
	padding-bottom: 5px;
	margin-bottom: 20px;
	&::after {
		border-bottom: 3px solid #1a1a1a;
		bottom: -3px;
		left: 0;
		position: absolute;
		content: "";
		width: 40%;
	}
}

.ttl04 {
	font-size: $l;
	border-bottom: 1px solid #999999;
	margin-bottom: 10px;
	padding-bottom: 5px;
}

.catch01 {
	font-weight: $font-medium;
	font-size: $xl;
	line-height: 1.6;
}


/* --------------------------------------------------- */
/* カラム制御 */
/* --------------------------------------------------- */

/* 複数カラム
/* --------------------------------------------------- */
.column {
	display: flex;
	flex-wrap: wrap;
}

.column > * {
	margin-bottom: 15px;

	* {
		max-width: 100%;
	}

	img {
		outline: 1px solid transparent;
		
		&:not([class*="wd-a"]) {
			width: 100%;
		}
	}
}

.column1 {
	&> * {
		width: 100%;
	}
}

.column2 {
	margin-left: -1.5%;
	margin-right: -1.5%;

	&> * {
		margin-left: 1.5%;
		margin-right: 1.5%;
		width: 47%;
	}
}

.column3,
.column6 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 31%;
	}
}

.column4 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 23%;
	}
}

.column5 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 18%;
	}
}

.sp-column1 {
	margin-left: 0;
	margin-right: 0;

	&> * {
		margin-left: 0;
		margin-right: 0;
		width: 100%;
	}
}

.sp-column2 {
	margin-left: -1.5%;
	margin-right: -1.5%;

	&> * {
		margin-left: 1.5%;
		margin-right: 1.5%;
		width: 47%;
	}
}

.sp-column3 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 31%;
	}
}



/* 幅指定カラム
------------------------------------------------------------ */
.flex-column {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	&> * {
		flex: 0 0 auto;
		width: 100%;
		
		&:not(:last-child) {
			margin-bottom: 20px;
		}
		
		img {
			display: block;
			margin: 0 auto;
		}

		* {
			max-width: 100%;
		}

	}
}


/* 画像回り込みカラム
------------------------------------------------------------ */
.float {
	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			max-width: 100%;
			width: 100%;
			text-align: center;
			margin: 0 0 10px;

			* {
				max-width: 100%;
			}
		}
	}


	&--right {
		.float__item {
			max-width: 100%;
			width: 100%;
			text-align: center;
			margin: 0 0 10px;

			* {
				max-width: 100%;
			}
		}
	}
}


.sp-float.float {

	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			float: left;
			margin: 0 20px 20px 0;
			text-align: center;
			max-width: 50%;

			* {
				max-width: 100%;
			}
		}
	}

	&--right {
		.float__item {
			float: right;
			margin: 0 0 20px 20px;
			text-align: center;
			max-width: 50%;

			* {
				max-width: 100%;
			}
		}
	}
}


/* --------------------------------------------------- */
/* リスト */
/* --------------------------------------------------- */
.list {
	list-style-type: none;

	& > * {
		position: relative;
		padding-left: 20px;
		margin-bottom: 5px;

		&::before {
			content: "";
			width: 6px;
			height: 6px;
			display: block;
			position: absolute;
			top: 0.7em;
			left: 5px;
		}
	}

	&--circle {
		& > * {
			&::before {
				width: 6px;
				height: 6px;
				border-radius: 100%;
			}
		}
	}

	&--black {
		& > * {
			&::before {
				background: $textColor;
			}
		}
	}

	&--decimal {
		list-style: decimal outside;
		margin: 0 0 15px 20px;
		
		& > * {
			padding-left: 0;
		}
	}
	
	&--border {
		& > * {
			&::before {
				content: "";
				height: 1px;
				background: #1a1a1a;
				@include centering-elements(false, true);
			}
		}
	}
}


/* --------------------------------------------------- */
/* box */
/* --------------------------------------------------- */
.box {
	padding: 15px;
	background: #fff;

	&--gray {
		background: #f9f9f9;
	}
	&--border-gray {
		border: 1px solid $borderColor;
	}
	&--red {
		background: #fde9e9;
		border: 1px solid #f8cfd4;
	}
	&--brackets {
		border: 1px solid $borderColor;
		position: relative;
		margin-bottom: 30px;
		margin: 0 auto;
		
		&::before,
		&::after {
			content:'';
			width: 30px;
			height: 30px;
			position: absolute;
			display: inline-block;
		}

		&::before {
			border-left: solid 1px #333333;
			border-top: solid 1px #333333;
			top: -1px;
			left: -1px;
		}

		&::after{
			border-right: solid 1px #333333;
			border-bottom: solid 1px #333333;
			bottom: -1px;
			right: -1px;
		}
	}
	&--border-black {
		position: relative;
		border-bottom: 1px solid #1a1a1a;
		border-top: 1px solid #1a1a1a;
		&::after {
			@include centering-elements(true, false);
			border-bottom: 3px solid #1a1a1a;
			top: 0;
			content: "";
			width: 70px;
		}
	}
}


/* --------------------------------------------------- */
/* iframe */
/* --------------------------------------------------- */
.iframe {
	position: relative;
	padding-bottom: 60%;
	height: 0;
	overflow: hidden;

	& > iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


/* --------------------------------------------------- */
/* table */
/* --------------------------------------------------- */
.table {
	width: 100%;
	border-top: 1px solid $borderColor;
	border-bottom: 1px solid $borderColor;
	table-layout: fixed;
	font-size: $xs;
	line-height: 1.6;
	word-break: break-all;
	word-wrap: break-word;

	th,
	td {
		padding: 10px;
		background: #fff;
		border-top: 1px solid $borderColor;
		border-bottom: 1px solid $borderColor;
		text-align: left;
		vertical-align: middle;
	}
	.bg-black {
		background: #444;
		color: $white;
	}
	.bg-gray {
		background: #f3f3f3;
	}
	.error {
		background: #fde9e9;
		color: #dd0000;
	}	
	
	
	&--sp-column1 {
		display: block;
		
		thead,
		tbody {
			display: block;
		}
		tr {
			display: block;
			&:first-child th {
				border-top: none;
			}
		}
		th,
		td {
			display: block;
			padding: 15px;
			border-left: none;
			border-right: none;
			border-bottom: none;
		}
		th {
			font-size: $m;
		}
	}
	
	&--wide {
		border: none;
		display: block;

		th,
		td {
			display: block;
			padding: 5px 8px;
			border-bottom-width: 0;
			border-right-width: 0;
			text-align: center;
		}
		th{  
			width: auto;
			text-align: left;
		}
		thead {
			display: block;
			float: left;
			overflow-x: scroll;
		}
		tbody {
			display: block;
			width: auto;
			overflow-x: auto;
			white-space: nowrap;

			tr { 
				display: inline-block; 
				margin: 0 -3px;

				&:last-child {
					th,
					td {
						border-right-width: 1px;
					}
				}
			}
		}
		thead th:last-child,
		tbody td:last-child {
			border-bottom-width: 1px;
		}
	}
	
	&--list {
		border: none;

		tr {
			display:block;
			margin: 0 0 20px;
		}
		th {
			display: block;
		}
		td {
			display: list-item;
			margin: 0 0 0 20px;
			padding: 5px 0 0;
			border: none;
			background: none;
		}
	}
	
}


/* --------------------------------------------------- */
/* btn */
/* --------------------------------------------------- */
.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 150px;
	height: 40px;
	margin: 5px 0;
	padding: 0 30px;
	color: #fff;
	text-align: center;
	text-decoration: none;
	line-height: 1.4;
	
	.icon-fix-left,
	.icon-fix-right {
		@include centering-elements(false, true);
	}
	.icon-fix-left { left: 10px; }
	.icon-fix-right { right: 10px; }
	
	&--orange {
		background: $baseColor;
	}
	&--black {
		background: $baseColor;
	}
	&--white {
		background: $white;
		opacity: 0.8;
		color: $baseColor;
	}
	&--neutral {
		border: 1px solid #ababab;
		color: #ababab;
		background: $white;
	}
	&--lg {
		width: 100%;
		height: 50px;
	}
	&--md {
		min-width: 80%;
		height: 50px;
		font-size: 1.7rem;
	}
	&--sm {
		min-width: 80%;
		height: 50px;
	}
}

button,
input[type="button"].btn,
input[type="submit"].btn {
	appearance: none;
}


/* --------------------------------------------------- */
/* pager */
/* --------------------------------------------------- */
.pager {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 30px 0 0;
	
	li {
		margin: 0 3px 10px;

		& > * {
			display: block;
			padding: 5px 12px;
			background: #f3f3f3;
			border: 1px solid #d5d5d5;
			color: $textColor;
			text-decoration: none;
			@include radius(3);
			
			&.current {
				background: $baseColor;
				border: 1px solid $baseColor;
				color: #fff;
				opacity: 1;
			}
		}
	}
	
	&__prev {
		position: absolute;
		left: 0;
	}
	&__next {
		position: absolute;
		right: 0;
	}
}


/* --------------------------------------------------- */
/* accordion */
/* --------------------------------------------------- */
.accordion,
.sp-accordion {
	position: relative;
	padding: 10px 30px 10px 10px;
	background: #f3f3f3;
	border: 1px solid #d5d5d5;
	cursor: pointer;
	line-height: 1.6;
	@include radius(3);
	
	&__icon {
		right: 10px;
		color: $baseColor;
		font-size: $xxs;
		@include centering-elements(false, true);
	}
	&.active {
		.accordion__icon::before {
			display: block;
			transform: rotate(180deg);
		}
	}
}
.accordion-hide {
	display: none;
	padding: 15px 0 0;
}


/* --------------------------------------------------- */
/* form */
/* --------------------------------------------------- */
.label-must {
	right: 0;
	padding: 2px 10px;
	background: #d30025;
	color: $white;
	font-size: $xxs;
	@include centering-elements(false, true);
}

.error-block {
	margin-top: 5px;
	font-weight: bold;
}

input[type="text"],
textarea,
select {
	color: $textColor;
	font-size: 16px;
}

input[type="text"] {
	appearance: none;
	height: 40px;
	padding: 0 10px;
	border: 1px solid #d2d2d2;
	@include radius(0);
}

textarea {
	appearance: none;
	padding: 5px 10px;
	width: 100%;
	height: 200px;
	border: 1px solid #d2d2d2;
	@include radius(0);
}

select {
	height: 40px;
	padding: 0 0 0 10px;
	background: $white;
	border: 1px solid #d2d2d2;
}


/* radio
----------------------------------------------------------- */
.radio {
	cursor: pointer;
	
	input[type="radio"] {
		display: none;
	}
	input[type="radio"] + span {
		position: relative;
		display: block;
		padding: 0 0 0 25px;
		color: $textColor;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			content: '';
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
			@include circle(14);
		}
		&::after {
			left: 5px;
			background: $baseColor;
			@include circle(8);
			opacity: 0;
		}
	}

	input[type="radio"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}


/* checkbox
----------------------------------------------------------- */
.checkbox {
	input[type="checkbox"] {
		display: none;
	}

	input[type="checkbox"] + span {
		position: relative;
		padding: 0 0 0 22px;
		color: $textColor;
		cursor: pointer;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			content: '';
			width: 12px;
			height: 12px;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
		}
		&::after {
			left: 1px;
			content: "\f00c";
			color: $baseColor;
			font-family: 'icomoon';
			font-size: $m;
			opacity: 0;
		}
	}
	input[type="checkbox"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}



/* --------------------------------------------------- */
/* label */
/* --------------------------------------------------- */
.label {
	width: 45px;
	padding: 2px 0;
	background: $baseColor;
	color: #fff;
	font-size: $xxxs;
	text-align: center;
}


/* --------------------------------------------------- */
/* news-list */
/* --------------------------------------------------- */
.news-list {
	margin: 0 0 10px;
	
	&__item {
		display: flex;
		align-items: center;
		padding: 0;
		align-items: flex-start;
		text-decoration: none;
		margin-bottom: 10px;
		&__thumb {
			position: relative;
			flex: 0 0 auto;
			width: 140px;
			height: 91px;
			margin: 0 15px 0 0;
			overflow: hidden;
			&__img {
				@include centering-elements(true, true);
				height: 100%;
			}
		}
		&__date {
			color: #808080;
			font-size: $xs;
			
		}
		&__text {
			flex: 1 1 auto;
			font-weight: $font-medium;
			color: $textColor;
			text-decoration: underline;
		}
	}
}


/* --------------------------------------------------- */
/* entry */
/* --------------------------------------------------- */
.entry {
	&__ttl {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin: 0 0 20px;
	}
	&__block {
		padding: 0 0 30px;
		border-bottom: 1px solid $borderColor;
		
		img {
			max-width: 100%;
    		height: auto;
		}
		b,strong {
			font-weight: bold;
		}
		h1 {
			font-size: $xxxl;
			line-height: 1.6;
			font-weight: $font-medium;
			margin: 0 0 20px;
			b,strong {
				font-weight: $font-medium;
			}
		}
		h2 {
			font-size: $xl;
			line-height: 1.6;
			font-weight: $font-medium;
			margin: 0 0 15px;
			b,strong {
				font-weight: $font-medium;
			}
		}
		h3 {
			font-size: $l;
			line-height: 1.6;
			font-weight: $font-medium;
			margin: 0 0 10px;
			b,strong {
				font-weight: $font-medium;
			}
		}
	}
}



/* ============================================================ */
/* .side */
/* ============================================================ */

/* --------------------------------------------------- */
/* snav */
/* --------------------------------------------------- */
.snav {
	margin: 0 0 20px;
	line-height: 1.4;

	&__ttl {
		&__link {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 65px;
			background: $textColor;
			color: $white;
			font-size: $m;
			text-align: center;

			&::before {
				top: 0;
				content: '';
				width: 25px;
				height: 2px;
				background: $baseColor;
				@include centering-elements(true, false);
			}
		}
	}

	&__inner {
		display: flex;
		flex-wrap: wrap;
		border: 1px solid $borderColor;
		border-top: none;
	}
	&__item {
		display: flex;
		align-items: center;
		width: 50%;
		margin: 0 0 -1px;
		padding: 15px 10px;
		border-bottom: 1px solid $borderColor;

		&:nth-child(2n+1) {
			border-right: 1px solid $borderColor;
		}

		&__text {
			flex: 1 1 auto;
			font-size: $xxs;
		}
		.icon-angle-right {
			flex: 0 0 auto;
			margin: 0 0 0 10px;
			font-size: $xxxs;
		}
	}
}
