@charset "utf-8";
/* ============================================================ */
/* room.html */
/* ============================================================ */
.room {
	.room-type {
		display: flex;
		flex-direction: column-reverse;
		margin-bottom: 20px;
		&:last-child {
			margin-bottom: 0;
		}
		&__img {
			min-height: 0.1%;
			& > img {
				max-width: 100%;
			}
		}
		&__text {
			margin-bottom: 30px;
		}
	}
}