@charset "utf-8";

$baseColor: #000;
$textColor: #333;
$borderColor: #b3b3b3;

$white: #fff;

@mixin clearfix {
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin radius($size) {
	border-radius: ($size + px);
}

@mixin circle($size){
	width: $size + px;
	height: $size + px;
	border-radius: 50%;
}

@mixin transition {
	transition: 0.3s all;
}

@mixin opacity($opacity: 0.7) {
	opacity: $opacity;
}

@mixin centering-elements($horizontal: true, $vertical: true) {
	position: absolute;
	@if $horizontal and $vertical {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	} @else if $horizontal {
		left: 50%;
		transform: translate(-50%, 0);
	} @else if $vertical {
		top: 50%;
		transform: translate(0, -50%);
	}
}


@keyframes scroll {
  0% {
    transform: translate3d(0, 0, 0);
  }

  60% {
    transform: translate3d(0, 80px, 0);
  }

  100% {
    transform: translate3d(0, 80px, 0);
  }
}

/* font-size
-------------------------------------------------- */
$xxxs: 1.0rem;
$xxs: 1.2rem;
$xs: 1.3rem;
$s: 1.4rem;
$m: 1.6rem;
$l: 1.8rem;
$xl: 2.0rem;
$xxl: 2.2rem;
$xxxl: 2.4rem;

/* font-weight
-------------------------------------------------- */
$font-regular: 400;
$font-medium: 500;