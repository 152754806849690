@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
.footer-contact {
	border-top: 1px solid #e6e6e6;
	.inner {
		position: relative;
		max-width: 1040px;
		margin: 0 auto;
		padding: 80px 20px 75px;
		.ttl02 { 
			top: 65px;
		}
	}
	&__body {
		padding-left: 140px;
		.time-info {
			font-size: 1.5rem;
		}
	}
	&__bnr {
		justify-content: center;
		&__item {
			display: block;
			position: relative;
			&::after {
				content: "";
				width: 95%;
				height: 85%;
				@include centering-elements(true, true);
				border: 1px solid rgba(255,255,255,0.4);
			}
			&__text {
				width: 95%;
				height: 95%;
				@include centering-elements(true, true);
				color: $white;
				font-size: 2.2rem;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.icon-angle-right {
				@include centering-elements(false, true);
				right: 15px;
				color: $white;
			}
			&:hover {
				transition: 0.3s all;
				opacity: 0.7;
			}
		}
	}
}

footer {
	background: $baseColor;
	.inner {
		max-width: 1040px;
		margin: 0 auto;
		padding: 50px 20px 60px;
		display: flex;
	}

	.footer-logo {
		display: block;
		width: 120px;
		margin-right: 20px;
		@media screen and (min-width:  767.5px) and (max-width:1024px) { width: 90px; }
	}
	
	.footer-address {
		color: #cccccc;
		font-weight: $font-regular;
		
		.time-info {
			font-size: 1.5rem;
			@media screen and (min-width:  767.5px) and (max-width:1024px) { font-size: $s }
		}
		a[href^="tel:"] {
			color: #cccccc;
		}
		.footer-inst {
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			width: 40px;
			height: 40px;
			border: 1px solid #cccccc;
			transition: 0.3s all;
			.icon-instagram,
			.icon-facebook {
				color: #cccccc;
				font-size: $xxl;
				text-decoration: none;
			}
			&:hover {
				opacity: 0.7;
			}
		}
	}
	
	.fnav {
		display: flex;
		margin: 0 0 0 auto;
		height: fit-content;
		@media screen and (min-width:  767.5px) and (max-width:1024px) { font-size: $s }
		&__body {
			border-left: 1px solid #4d4d4d;
			margin-left: 30px;
			padding-left: 10px;
			&__item {
				margin-bottom: 10px;
				a {
					color: #cccccc;
					text-decoration: none;
					&:hover {
						text-decoration: underline;
					}
				}
				.icon-angle-right {
					margin-right: 5px;
					color: #cccccc;
				}
			}
		}
	}

	.copyright {
		text-align: center;
		padding: 20px 0;
		color: #cccccc;
		border-top: 1px solid #4d4d4d;
	}
}