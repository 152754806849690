@charset "utf-8";
/* ============================================================ */
/* index.html */
/* ============================================================ */
#index {
	.visual {
		position: relative;
		width: 100%;
		text-align: left;
		
		.visual__inner {
			position: relative;
			overflow: hidden;
			height: 100vh;
			width: 100%;
			.visual__slider {
				z-index: -1;
				.slick-slide {
					height: 100vh;
					overflow: hidden;
				}
				&__item.slick-slide {
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center top;
				}

				&__item {
					&:nth-child(1).slick-slide {
						background-image: url(/common/img/index/pic_visual01_sp.jpg);
					}
					&:nth-child(2).slick-slide {
						background-image: url(/common/img/index/pic_visual02_sp.jpg);
					}
					&:nth-child(3).slick-slide {
						background-image: url(/common/img/index/pic_visual03_sp.jpg);
					}
					&:nth-child(4).slick-slide {
						background-image: url(/common/img/index/pic_visual04_sp.jpg);
					}
					&:nth-child(5).slick-slide {
						background-image: url(/common/img/index/pic_visual05_sp.jpg);
					}
				}
			}
			.scroll {
			    display: block;
				position: absolute;
				bottom: 0;
				left: 50%;
				margin-left: -40px;
				width: 80px;
				height: 80px;
				overflow: hidden;
				&:before {
					display: block;
					position: absolute;
					top: 0;
					left: 40px;
					width: 1px;
					height: 100%;
					background: rgba(255, 255, 255, .5);
					content: "";
				}
				&:after {
					display: block;
					position: absolute;
					top: 0;
					left: 40px;
					width: 1px;
					height: 100%;
					background: #fff;
					transform: scale(0);
					animation-name: scroll;
					animation-fill-mode: both;
					animation-iteration-count: infinite;
					animation-timing-function: cubic-bezier(.645,.045,.355,1);
					animation-duration: 2s;
					content: "";
				}
			}
		}
	}
	.contents {
		.ttl01 {
			font-size: $xxxl;
			margin: 0 0 20px;
			&::before {
				display: none;
			}
		}
		.row__inner {
			padding: 30px 0;
		}
		.index-about {
			.row__inner {
				padding-bottom: 0;
			}
			&__body {
				padding: 0 10px 40px;
				background-image: url(/common/img/index/bg_index_01.png),url(/common/img/index/bg_index_02.png);
				background-repeat: no-repeat,repeat-x;
				background-position: left -40px top -50px,bottom center;
				background-size: 60% auto,cover;
			}
		}
		.index-cont {
			margin-bottom: 40px;
			&__item {
				width: 100%;
				display: block;
				overflow: hidden;
				position: relative;
				margin-bottom: 2px;
				&__text {
					@include centering-elements(true, true);
					display: flex;
					justify-content: center;
					align-items: center;
					color: $white;
					flex-direction: column;
					width: 80%;
					&--ja {
						font-weight: $font-regular;
						border-bottom: 1px solid rgba(255,255,255,0.6);
						font-size: 2.8rem;
						@media screen and (min-width:  767.5px) and (max-width:1024px) { font-size: 2.2rem; }
					}
					&--en {
						font-weight: $font-regular;
					}
					
				}
			}
		}
		.index-gallery {
			.row__inner {
				padding-bottom: 0;
				margin-bottom: 40px;
			}
			&__img {
				margin-bottom: 30px;
			}
			&__btn {
				.btn--nobg {
					background: $baseColor;
					color: $white;
					boder: none;
				}
			}
		}
		.index-inst {
			padding-bottom: 55px;
			&__ttl {
				font-size: 2.6rem;
				text-align: center;
				margin-bottom: 15px;
			}
			.instagram {
				display: flex;
				flex-wrap: wrap;

				&__item {
					width: calc(100% / 3);

					&__link {
						position: relative;
						display: block;
						overflow: hidden;

						&:hover {
							.instagram__item__content {
								opacity: 1;
								visibility: visible;
								transform: translateY(0);
							}
						}
					}
					&__img {
						position: relative;
						padding-bottom: 100%;
						overflow: hidden;

						& > * {
							min-width: 100%;
							min-height: 100%;
							max-width: 100px;
							@include centering-elements(true, true);
						}
					}
				}
			}
		}
	}
}