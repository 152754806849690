@charset "utf-8";
/* ============================================================ */
/* room.html */
/* ============================================================ */
.room {
	.room-type {
		display: flex;
		margin-bottom: 60px;
		&:last-child {
			margin-bottom: 0;
		}
		&__img {
			padding-right: 20px;
			& > img {
				max-width: 100%;
			}
		}
		&__text {
			padding-left: 25px;
		}
	}
}