@import url('https://fonts.googleapis.com/css?family=Noto+Serif+JP:400,500&display=swap&subset=japanese');
@charset "utf-8";

@import "_reset";
@import "_icon";
@import "_mixin";

@media print, screen and (min-width: 767.5px) {
	@import "pc/_base";
	@import "pc/_header";
	@import "pc/_contents";
	@import "pc/_footer";
	@import "pc/_index";
	@import "pc/_room";
	@import "pc/_about";
	@import "pc/_scene";
	@import "pc/_menu";
	@import "pc/_gallery";
}

@media screen and (max-width: 767.499px) {
	@import "sp/_base";
	@import "sp/_header";
	@import "sp/_contents";
	@import "sp/_footer";
	@import "sp/_index";
	@import "sp/_room";
	@import "sp/_about";
	@import "sp/_scene";
	@import "sp/_menu";
}
@import "_utility";



