@charset "utf-8";
/* ============================================================ */
/* menu.html */
/* ============================================================ */
.menu {
	.menu-title {
		text-align: center;
		width: 40%;
		margin: 0 auto 30px;
		&::after {
			@include centering-elements(true, false);
			width: 30%;
		}
	}
    .ttl03 {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: wrap;
        .__sub {
            font-weight: $font-medium;
            font-size: $s;
        }
    }
	.menu-item {
		position: relative;
		display: flex;
		justify-content: space-between;
		margin: 0;
		font-size: $l;
		&::after {
			@include centering-elements(true, true);
			width: 90%;
			border-bottom: 1px solid #cccccc;
			content: "";
			z-index: -1;
		}
		&__name,
		&__price {
			background: $white;
		}
		&__name {
			padding: 0 15px 0 0;
			font-weight: $font-medium;
		}
		&__price {
			padding: 0 0 0 15px;
		}
		&__sub {
			font-size: $m;
			color: #333333;
		}
	}
}