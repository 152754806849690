@charset "utf-8";
/* ============================================================ */
/* gallery.html */
/* ============================================================ */
.gallery {
	&__item {
		display: block;
		overflow: hidden;
		position: relative;
		&__img {
			transition-duration: 0.5s;
			transform: scale(1.1,1.1);
		}
		&:hover {
			.gallery__item__img {
				transform: scale(1.03,1.03);
				opacity: 0.7;
			}
		}
	}
}