@charset "utf-8";
/* ============================================================ */
/* index.html */
/* ============================================================ */
#index {
	.visual {
		position: relative;
		width: 100%;
		text-align: left;
		
		.visual__inner {
			position: relative;
			overflow: hidden;
			height: 100vh;
			width: 100%;
			.visual__slider {
				z-index: -1;
				.slick-slide {
					height: 100vh;
					overflow: hidden;
				}
				&__item.slick-slide {
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center top;
				}

				&__item {
					&:nth-child(1).slick-slide {
						background-image: url(/common/img/index/pic_visual01_pc.jpg);
					}
					&:nth-child(2).slick-slide {
						background-image: url(/common/img/index/pic_visual02_pc.jpg);
					}
					&:nth-child(3).slick-slide {
						background-image: url(/common/img/index/pic_visual03_pc.jpg);
					}
					&:nth-child(4).slick-slide {
						background-image: url(/common/img/index/pic_visual04_pc.jpg);
					}
					&:nth-child(5).slick-slide {
						background-image: url(/common/img/index/pic_visual05_pc.jpg);
					}
				}
			}
			.scroll {
			    display: block;
				position: absolute;
				bottom: 0;
				left: 50%;
				margin-left: -40px;
				width: 80px;
				height: 80px;
				overflow: hidden;
				z-index: 1;
				&:before {
					display: block;
					position: absolute;
					top: 0;
					left: 40px;
					width: 1px;
					height: 100%;
					background: rgba(255, 255, 255, .5);
					content: "";
				}
				&:after {
					display: block;
					position: absolute;
					top: 0;
					left: 40px;
					width: 1px;
					height: 100%;
					background: #fff;
					transform: scale(0);
					animation-name: scroll;
					animation-fill-mode: both;
					animation-iteration-count: infinite;
					animation-timing-function: cubic-bezier(.645,.045,.355,1);
					animation-duration: 2s;
					content: "";
				}
			}
			&__text {
				@include centering-elements(true, false);
				bottom: 30px;
				display: flex;
				align-items: center;
				max-width: 1040px;
				width: 100%;
				justify-content: flex-end;
				color: $white;
				font-size: $m;
				line-height: 1.0;
				padding: 0 20px;
				font-weight: $font-medium;
				& > * {
					display: flex;
					align-items: center;
				}
				&__tel {
					font-size: 2.8rem;
					padding-left: 10px;
				}
			}
		}
	}
	.contents {
		.ttl01 {
			font-size: 2.6rem;
			margin: 0 0 40px;
			&::before {
				display: none;
			}
		}
		.row__inner {
			position: relative;
			padding: 30px 20px 55px;
			&__body {
				margin-left: 140px;
			}
		}
		.news-list {
			border-top: none;
			margin: 0 -10px 15px;
			
			&__item {
				border-bottom: none;
				display: block;
				padding:  0;
				&__thumb {
					position: relative;
					flex: 0 0 auto;
					width: 273px;
					height: 176px;
					margin: 0 15px 0 0;
					overflow: hidden;
					&__img {
						width: auto;
						max-width: none;
					}
				}
			}
		}
		
		.index-about {
			background-image: url(/common/img/index/bg_index_01.png),url(/common/img/index/bg_index_02.png);
			background-repeat: no-repeat,repeat-x;
			background-position: left 5% bottom 40px,bottom center;
			background-size: auto 450px,1600px auto;
			@media screen and (min-width:  767.5px) and (max-width:1024px) { background-position: left 3% bottom 20px,bottom center;background-size: auto 350px,1450px auto; }
		}
		
		.index-cont {
			display: flex;
			margin-bottom: 80px;
			&__item {
				width: calc( 100% / 3);
				display: block;
				overflow: hidden;
				position: relative;
				&__img {
					width: calc( 100% / 3);
					transition-duration: 0.5s;
					transform: scale(1.1,1.1);
				}
				&__text {
					@include centering-elements(true, true);
					display: flex;
					justify-content: center;
					align-items: center;
					color: $white;
					flex-direction: column;
					width: 80%;
					&--ja {
						font-weight: $font-regular;
						border-bottom: 1px solid rgba(255,255,255,0.6);
						font-size: 2.8rem;
						@media screen and (min-width:  767.5px) and (max-width:1024px) { font-size: 2.2rem; }
					}
					&--en {
						font-weight: $font-regular;
					}
					
				}
				&:hover {
					.index-cont__item__img {
						transform: scale(1.03,1.03);
						opacity: 0.7;
					}
				}
			}
		}
		
		.index-gallery {
			.row__inner {
				position: relative;
				padding-bottom: 0;
				margin-bottom: 80px;
			}
			&__img {
				z-index: -1;
			}
			&__btn {
				position: absolute;
				right: 60px;
				bottom: 55px;			
				
			}
		}
		
		.index-inst {
			&__ttl {
				font-size: 2.6rem;
				text-align: center;
				margin-bottom: 15px;
			}
			.instagram {
				display: flex;
				flex-wrap: wrap;
				&__item {
					width: calc((100% - 1px) / 6);
					&__link {
						position: relative;
						display: block;
						overflow: hidden;
						&:hover {
							.instagram__item__content {
								opacity: 1;
								visibility: visible;
								transform: translateY(0);
							}
						}
					}
					&__img {
						position: relative;
						padding-bottom: 100%;
						overflow: hidden;
						& > * {
							min-width: 100%;
							max-width: 100px;
							min-height: 100%;
							@include centering-elements(true, true);
						}
					}
					&__content {
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						background: rgba($baseColor, 0.9);
						color: $white;
						padding: 15px 20px;
						text-align: left;
						transform: translateY(100%);
						@include transition;
						&__date {
							margin: 0 0 10px;
							font-size: $xs;
							font-family: 'Montserrat', sans-serif;
						}
						.caption {
							position: relative;
							height: calc(14px * 1.6 * 7);
							margin: 0;
							font-size: $xs;
							line-height: 1.6;
							overflow: hidden;
						}
					}
				}
			}
		}
	}
}