@charset "utf-8";
/* ============================================================ */
/* about.html */
/* ============================================================ */
.about {
	.about-commitment {
		background: url(/common/img/about/bg_about_01.png) bottom right 10% no-repeat;
		background-size: contain;
		.ttl03 {
			text-align: center;
			width: 80%;
			margin: 0 auto 30px;
			&::after {
				@include centering-elements(true, false);
				width: 30%;
			}
		}
		.about-copy {
			display: flex;
			justify-content: center;
			&__item {
				writing-mode: vertical-rl;
				font-size: 2.6rem;
				margin: 10px auto 40px;
			}
			_:-ms-lang(x)::-ms-backdrop, .about-copy__item {
				display: table-cell;
			}
			
		}
	}
	
	.about-img {
		display: flex;
		&__item {
			width: calc( 100% / 3 );
		}
	}
	
	.about-cont {
		background: url(/common/img/common/bg_pattern_01.png) center center repeat;
		background-size: cover;
		&__item {
			position: relative;
			padding: 50px 0 0;
			&__img {
				position: absolute;
				top: 50px;
				width: 100%;
			}
			.row__inner {
				padding: 30px 10px 0;
			}
			&__body {
				background: rgba(255,255,255,0.95);
				margin-top: 40%;
				width: 100%;
				position: relative;
				padding: 30px 0 30px 30px;
				&__text {
					padding-right: 30px;
				}
			}
			&--left {
				.about-cont__item__img {
					left: 0;
				}
				.about-cont__item__body {
					margin-left: auto;
				}
			}
			&--right {
				.about-cont__item__img {
					right: 0;
				}
				.about-cont__item__body {
					margin-right: auto;
				}
			}
			&:last-child {
				.row__inner {
					padding: 30px 20px 50px;
				}
			}
		}
	}
}