@charset "utf-8";
/* ============================================================ */
/* .contents */
/* ============================================================ */
.contents {
	.row {
		&__inner {
			max-width: 1040px;
			padding: 30px 20px 55px;
			margin: 0 auto;
			&__narrow {
				padding: 0 70px 0;
			}
		}
	}
}


/* ============================================================ */
/* 汎用スタイル */
/* ============================================================ */
.bg-pattern {
	background: url(/common/img/common/bg_pattern_01.png) center center repeat;
	background-size: 1600px auto;
}

.link_scroll {
	margin-top: -75px!important;
	padding-top: 75px!important;
}

/* --------------------------------------------------- */
/* テキスト */
/* --------------------------------------------------- */
p {
	margin-bottom: 15px;
	
	&:last-child {
		margin-bottom: 0;
	}
}

.indent {
	display: table;
	
	& > *:first-child {
		display: table-cell;
		white-space: nowrap;
	}
}

.font-tel {
	font-size: 3.4rem;
	display: flex;
	align-items: center;
}

a[href^="tel:"] {
	cursor: default;
	text-decoration: none;
	color: $textColor;
	&:hover {
		opacity: 1.0;
	}
}

.time-info {
	line-height: 1.0;
	font-size: $m;
	&__label {
		border-right: 1px solid #cccccc;
		padding-right: 10px;
	}
}

/* --------------------------------------------------- */
/* 下層ヘッダー */
/* --------------------------------------------------- */
.under-header {
	height: 400px;
	background-image: url(/common/img/common/bg_header.png);
	background-position: center center;
	background-size: cover;
	position: relative;
	&::after {
		content: "";
		border-right: 1px solid $white;
		@include centering-elements(true, false);
		bottom: 0;
		height: 66px;
	}
	
	.about & { background-image: url(/common/img/about/bg_header.png);}
	.menu & { background-image: url(/common/img/menu/bg_header.png);}
	.room & { background-image: url(/common/img/room/bg_header.png);}
	.scene & { background-image: url(/common/img/scene/bg_header.png);}
	.gallery & { background-image: url(/common/img/gallery/bg_header.png);}
	.access & { background-image: url(/common/img/access/bg_header.png);}
	.news & { background-image: url(/common/img/news/bg_header.png);}
	.contact & { background-image: url(/common/img/contact/bg_header.png);}
	
	@media screen and (min-width:  767.5px) and (max-width:1024px) { height: 40vh; }
	@media screen and (max-height: 630px) {
		height: 250px;
	}
}

/* --------------------------------------------------- */
/* 見出し */
/* --------------------------------------------------- */
.ttl01 {
	font-size: 3.6rem;
	text-align: center;
	font-weight: $font-medium;
	padding: 40px 0 0;
	position: relative;
	&::before {
		content: "";
		border-right: 1px solid $baseColor;
		@include centering-elements(true, false);
		top: 0;
		height: 33px;
	}
}

.ttl02 {
	position: absolute;
	top: 15px;
	left: 20px;
	display: flex;
	&--ja {
		font-size: 2.6rem;
		font-weight: $font-medium;
		transform: rotate(.001deg);
		writing-mode: vertical-rl;
	}
	&--en {
		transform: rotate(.001deg);
		writing-mode: vertical-rl;
		line-height: 1.0;
		padding-top: 15px;
		color: #b3b3b3;
		font-size: 1.5rem;
		font-weight: $font-regular;
	}
}

.ttl03 {
	font-size: 3.0rem;
	font-weight: $font-medium;
	position: relative;
	border-bottom: 1px solid #1a1a1a;
	padding-bottom: 10px;
	margin-bottom: 20px;
	&::after {
		border-bottom: 3px solid #1a1a1a;
		bottom: -3px;
		left: 0;
		position: absolute;
		content: "";
		width: 45%;
	}
}

.ttl04 {
	font-size: $l;
	border-bottom: 1px solid #999999;
	margin-bottom: 10px;
	padding-bottom: 5px;
}

.catch01 {
	font-weight: $font-medium;
	font-size: $xxl;
}

/* --------------------------------------------------- */
/* カラム制御 */
/* --------------------------------------------------- */

/* 複数カラム
/* --------------------------------------------------- */
.column {
	display: flex;
	flex-wrap: wrap;
}

.column > * {
	margin-bottom: 15px;

	* {
		max-width: 100%;
	}

	img {
		outline: 1px solid transparent;
		
		&:not([class*="wd-a"]) {
			width: 100%;
		}
	}
}

.column1 {
	&> * {
		width: 100%;
	}
}

.column2 {
	 margin-left: -5px;
	 margin-right: -5px;

	 &> * {
		 margin-left: 5px;
		 margin-right: 5px;
		 width: calc((100% - 21px) / 2 );
	 }
	&--wide {
		margin-left: -20px;
		margin-right: -20px;

		&> * {
		 margin-left: 20px;
		 margin-right: 20px;
		 width: calc((100% - 81px) / 2 );
		}
	}
 }

.column3 {
	margin-left: -10px;
	margin-right: -10px;

	&> * {
		margin-left: 10px;
		margin-right: 10px;
		width: calc((100% - 61px) / 3);
	}
}

.column4 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 23%;
	}
}

.column5 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 18%;
	}
}

.column6 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 14.6%;
	}
}


/* 幅指定カラム
------------------------------------------------------------ */
.flex-column {
	display: flex;
	justify-content: space-between;

	&> * {
		flex: 0 0 auto;

		* {
			max-width: 100%;
		}

	}
}


/* 画像回り込みカラム
------------------------------------------------------------ */
.float {

	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			float: left;
			margin: 0 20px 20px 0;
			max-width: 50%;

			* {
				width: 100%;
			}
		}
	}

	&--right {
		.float__item {
			float: right;
			margin: 0 0 20px 20px;
			max-width: 50%;

			* {
				width: 100%;
			}

		}
	}
}


/* --------------------------------------------------- */
/* リスト */
/* --------------------------------------------------- */
.list {
	list-style-type: none;

	& > * {
		position: relative;
		padding-left: 15px;
		margin-bottom: 5px;

		&::before {
			content: "";
			width: 6px;
			height: 6px;
			display: block;
			position: absolute;
			top: 0.7em;
			left: 0;
		}
	}

	&--circle {
		& > * {
			&::before {
				width: 6px;
				height: 6px;
				border-radius: 100%;
			}
		}
	}
	
	&--black {
		& > * {
			&::before {
				background: $textColor;
			}
		}
	}
	
	&--decimal {
		list-style: decimal outside;
		margin: 0 0 15px 20px;
		& > * {
			padding-left: 0;
		}
	}
	&--border {
		& > * {
			&::before {
				content: "";
				height: 1px;
				background: #1a1a1a;
				@include centering-elements(false, true);
			}
		}
	}
}


/* --------------------------------------------------- */
/* box */
/* --------------------------------------------------- */
.box {
	padding: 25px;
	box-sizing: border-box;
	background: $white;

	&--gray {
		background: #f2f2f2;
	}
	
	&--white {
		background: $white;
	}

	&--border-gray {
		border: 1px solid $borderColor;
	}
	
	&--red {
		background: #fde9e9;
		border: 1px solid #f8cfd4;
	}
	&--brackets {
		border: 1px solid $borderColor;
		position: relative;
		margin-bottom: 30px;
		margin: 0 auto;
		text-align: center;
		width: max-content;
		
		&::before,
		&::after {
			content:'';
			width: 30px;
			height: 30px;
			position: absolute;
			display: inline-block;
		}

		&::before {
			border-left: solid 1px #333333;
			border-top: solid 1px #333333;
			top: -1px;
			left: -1px;
		}

		&::after{
			border-right: solid 1px #333333;
			border-bottom: solid 1px #333333;
			bottom: -1px;
			right: -1px;
		}
	}
	&--border-black {
		position: relative;
		border-bottom: 1px solid #1a1a1a;
		border-top: 1px solid #1a1a1a;
		&::after {
			@include centering-elements(true, false);
			border-bottom: 3px solid #1a1a1a;
			top: 0;
			content: "";
			width: 70px;
		}
	}
}


/* --------------------------------------------------- */
/* iframe */
/* --------------------------------------------------- */
.iframe {
	position: relative;
	padding-bottom: 43%;
	height: 0;
	overflow: hidden;

	& > iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


/* --------------------------------------------------- */
/* table */
/* --------------------------------------------------- */
.table {
	width: 100%;
	
	th,
	td {
		padding: 15px;
		background: $white;
		border-top: 1px solid $borderColor;
		border-bottom: 1px solid $borderColor;
		font-size: $s;
		text-align: left;
		vertical-align: middle;
		line-height: 1.6;
	}
	.bg-black {
		background: #444;
		color: $white;
	}
	.bg-gray {
		background: #f2f2f2;
	}
	.error {
		background: #fde9e9;
		color: #dd0000;
	}	
}

.table--center {
	th,
	td {
		text-align: center;
	}
}

.table--thin {
	th,
	td {
		padding: 5px 10px;
		line-height: 1.4;
	}
}


/* --------------------------------------------------- */
/* btn */
/* --------------------------------------------------- */
.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 150px;
	height: 55px;
	padding: 0 30px;
	color: $white;
	font-size: $m;
	text-align: center;
	text-decoration: none;
	line-height: 1.4;
	cursor: pointer;
	transition: 0.3s all;

	.icon-fix-left,
	.icon-fix-right {
		@include centering-elements(false, true);
	}
	.icon-fix-left { left: 15px; }
	.icon-fix-right { right: 15px; }
	
	&:hover {
		opacity: 0.7;
		text-decoration: none;
	}
	&--black {
		background: $baseColor;
	}
	&--nobg {
		background: none;
		border: 1px solid $white;
		&:hover {
			background: $white;
			color: $baseColor;
			opacity: 1.0;
		}
	}
	&--neutral {
		border: 1px solid #ababab;
		color: #ababab;
		background: $white;
	}
	&--lg {
		min-width: 300px;
		height: 60px;
		font-size: $m;
	}
	&--md {
		min-width: 280px;
		height: 55px;
		font-size: 1.7rem;
		@media screen and (min-width:  767.5px) and (max-width:1024px) { min-width: 240px; }
	}
	&--sm {
		min-width: inherit;
		height: 45px;
		font-size: $m;
	}
}

button,
input[type="button"].btn,
input[type="submit"].btn {
	appearance: none;
}


/* --------------------------------------------------- */
/* pager */
/* --------------------------------------------------- */
.pager {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 30px 0 0;
	
	li {
		margin: 0 3px 5px;

		& > * {
			display: block;
			padding: 10px 15px;
			background: #f3f3f3;
			border: 1px solid #d5d5d5;
			color: $textColor;
			text-decoration: none;
			@include radius(3);
			
			&.current,
			&:hover {
				background: $baseColor;
				border: 1px solid $baseColor;
				color: $white;
				opacity: 1;
				text-decoration: none;
			}
		}
	}
	
	&__prev {
		position: absolute;
		left: 0;
	}
	&__next {
		position: absolute;
		right: 0;
	}
}


/* --------------------------------------------------- */
/* accordion */
/* --------------------------------------------------- */
.accordion {
	position: relative;
	padding: 10px 30px 10px 10px;
	background: #f3f3f3;
	border: 1px solid #d5d5d5;
	cursor: pointer;
	line-height: 1.6;
	@include radius(3);
	
	&__icon {
		right: 10px;
		color: $baseColor;
		@include centering-elements(false, true);
	}
	&.active {
		.accordion__icon::before {
			display: block;
			transform: rotate(180deg);
		}
	}
}
.accordion-hide {
	display: none;
	padding: 15px 0 0;
}


/* --------------------------------------------------- */
/* form */
/* --------------------------------------------------- */
.label-must {
	right: 5px;
	padding: 2px 10px;
	background: #d30025;
	color: $white;
	font-size: $xxs;
	@include centering-elements(false, true);
}

.error-block {
	margin-top: 5px;
	font-weight: $font-medium;
}

input[type="text"] {
	appearance: none;
	height: 40px;
	padding: 0 10px;
	border: 1px solid #d2d2d2;
	color: $textColor;
	font-size: $m;
	font-family: inherit!important;
	@include radius(0);
	&::-webkit-input-placeholder {
		color: #B5B5B5;
	}
	&::-moz-placeholder {
		color: #B5B5B5;
	}
	&:-ms-input-placeholder {
		color: #B5B5B5;
	}
}

textarea {
	font-family: inherit;
	appearance: none;
	padding: 5px 10px;
	width: 100%;
	height: 200px;
	border: 1px solid #d2d2d2;
	color: $textColor;
	@include radius(0);
}

select {
	height: 40px;
	padding: 0 0 0 10px;
	background: $white;
	border: 1px solid #d2d2d2;
}


/* radio
----------------------------------------------------------- */
.radio {
	cursor: pointer;
	
	input[type="radio"] {
		display: none;
	}
	input[type="radio"] + span {
		position: relative;
		display: block;
		padding: 0 0 0 25px;
		color: $textColor;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			content: '';
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
			@include circle(14);
		}
		&::after {
			left: 5px;
			background: $baseColor;
			@include circle(8);
			opacity: 0;
		}
	}

	input[type="radio"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}


/* checkbox
----------------------------------------------------------- */
.checkbox {
	input[type="checkbox"] {
		display: none;
	}

	input[type="checkbox"] + span {
		position: relative;
		padding: 0 0 0 22px;
		color: $textColor;
		cursor: pointer;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			content: '';
			width: 12px;
			height: 12px;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
		}
		&::after {
			left: 1px;
			content: "\f00c";
			color: $baseColor;
			font-family: 'icomoon';
			font-size: $m;
			opacity: 0;
		}
	}
	input[type="checkbox"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}



/* --------------------------------------------------- */
/* label */
/* --------------------------------------------------- */
.label {
	width: 80px;
	height: 25px;
	background: $baseColor;
	color: #fff;
	font-size: $xxs;
	text-align: center;
	line-height: 25px;
}


/* --------------------------------------------------- */
/* news-list */
/* --------------------------------------------------- */
.news-list {
	margin: 0 0 40px;
	border-top: 1px solid $borderColor;
	
	&__item {
		display: flex;
		align-items: center;
		padding: 15px 0;
		border-bottom: 1px solid $borderColor;
		text-decoration: none;
		width: 100%;
		align-items: flex-start;
		transition: 0.3s all;
		&__thumb {
			position: relative;
			flex: 0 0 auto;
			width: 140px;
			height: 91px;
			margin: 0 15px 0 0;
			overflow: hidden;
			&__img {
				@include centering-elements(true, true);
				height: 100%;
			}
		}
		&:hover {
			opacity: 0.7;
			
		}
		
		&__date {
			color: #808080;
			
		}
		&__text {
			flex: 1 1 auto;
			font-size: $m;
			font-weight: $font-medium;
			color: $textColor;
		}
	}
}


/* --------------------------------------------------- */
/* entry */
/* --------------------------------------------------- */
.entry {
	&__ttl {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin: 0 0 40px;
		&__date {
			color: #808080;
		}
	}
	&__block {
		padding: 0 0 50px;
		border-bottom: 1px solid $borderColor;
		img {
			max-width: 100%;
    		height: auto;
		}
		b,strong {
			font-weight: bold;
		}
		h1 {
			font-size: 3.2rem;
			line-height: 1.6;
			font-weight: $font-medium;
			margin: 0 0 25px;
			b,strong {
				font-weight: $font-medium;
			}
		}
		h2 {
			font-size: $xxxl;
			line-height: 1.6;
			font-weight: $font-medium;
			margin: 0 0 15px;
			b,strong {
				font-weight: $font-medium;
			}
		}
		h3 {
			font-size: $l;
			line-height: 1.6;
			font-weight: $font-medium;
			margin: 0 0 15px;
			b,strong {
				font-weight: $font-medium;
			}
		}
	}
}




/* ============================================================ */
/* .side */
/* ============================================================ */

/* --------------------------------------------------- */
/* snav */
/* --------------------------------------------------- */
.snav {
	&__ttl {
		&__link {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 120px;
			padding: 5px 0 0;
			background: $textColor;
			color: $white;
			font-size: $l;
			text-align: center;
			
			@media screen and (min-width:  767.5px) and (max-width:1024px) { height: 11.8vh; }

			&::before {
				top: 0;
				content: '';
				width: 50px;
				height: 5px;
				background: $baseColor;
				@include centering-elements(true, false);
			}
		}
	}
	&__item {
		display: flex;
		align-items: center;
		padding: 20px 15px;
		border: 1px solid $borderColor;
		border-top: none;

		&__text {
			flex: 1 1 auto;
			font-size: $s;
		}
		.icon-angle-right {
			flex: 0 0 auto;
			margin: 0 0 0 10px;
			font-size: $xxs;
		}
		&:hover {
			opacity: 1;
			background: #f0f0f0;
		}
	}
}
